:root {

  --dark: #444444!important;
  --dark-grey: #3D3D3D!important;
  --light: #ffff!important;
  --yellow: #FEF27B!important;
  --blue: #7CC7FA!important;
  --dark-sea-green: #8EA6B2!important;

  --super-extra-small-indent: 14px;
  --extra-small-indent: 18px;
  --small-indent: 21px;
  --regular-indent: 24px;
  --medium-indent: 36px;
  --large-indent: 48px;
  --extra-large-indent: 72px;
  --super-extra-large-indent: 96px;

}

@font-face {
  font-family: "Sofia Pro Bold";
  src: url("./Components/fonts/Sofia Pro Bold Az.otf");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro Semi Bold";
  src: url("./Components/fonts/Sofia Pro Semi Bold Az.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro Medium";
  src: url("./Components/fonts/Sofia Pro Medium Az.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro Regular";
  src: url("./Components/fonts/Sofia Pro Regular Az.otf");
  font-weight: lighter;
  font-style: normal;
}

body {
  background-color: var(--dark)!important;
  color: var(--light)!important;
}

h1 {
  font-family: "Sofia Pro Semi Bold", sans-serif;
  font-size: var(--extra-large-indent)!important;
  line-height: 100%!important;
  cursor: default;
  margin: 0px!important;
}

h2 {
  font-family: "Sofia Pro Bold", sans-serif;
  font-size: var(--medium-indent)!important;
  line-height: 116.6%!important;
  cursor: default;
  margin: 0px!important;
}

h3 {
  font-family: "Sofia Pro Bold", sans-serif;
  font-size: var(--regular-indent)!important;
  line-height: 116.6%!important;
  cursor: default;
  margin: 0px!important;
}

h4 {
  font-family: "Sofia Pro Bold", sans-serif;
  font-size: var(--super-extra-small-indent)!important;
  text-transform: uppercase;
  line-height: 116.6%!important;
  cursor: default;
  margin: 0px!important;
}

p {
  font-family: "Sofia Pro Regular", sans-serif;
  font-size: var(--small-indent)!important;
  line-height: 1.3!important;
  cursor: default;
  margin: 0px!important;
}

hr {
  margin: 0px!important;
}

.button {
  font-family: "Sofia Pro Bold", sans-serif;
  font-size: var(--small-indent)!important;
  color: var(--light)!important;
  align-items: baseline;
  padding: 0!important;
  overflow: hidden!important;
  background-color: transparent!important;
  border-style: hidden!important;
  border-radius: 0!important;
}

.button:hover {
    border-color: var(--yellow)!important;
}

.arrow {
  padding-left: 0!important;
  margin-left: var(--extra-small-indent);
}

.three-dots {
    overflow: hidden!important;
    margin-top: var(--regular-indent);
    margin-bottom: var(--regular-indent);
}

@media only screen and (max-width: 576px) {
    
  h1 {
    font-size: var(--large-indent)!important;
  }

}