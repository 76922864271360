.main-content {
    position: relative;
    z-index: 1!important;
    display: flex;
    min-height: 550px!important;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    color: var(--light);
    background-color: var(--dark);
    overflow: hidden!important;
}

video {
    opacity: 0.6;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
  }

  .video-background-color {
    object-fit: cover;
    overflow: hidden!important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--blue);
    z-index: 2;
  }

.main-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.main-header-container {
    margin-bottom: var(--large-indent);
}

.main-header-container-first {
    overflow: hidden!important;
}

.main-header-container-second {
    overflow: hidden!important;
}

.main-text-description {
    max-width: 900px;
}

@media only screen and (max-height: 992px) {

    .main-text {
        padding-bottom: calc(var(--large-indent)*3)!important;
    }
}

@media only screen and (max-height: 568px) {

    .main-text-description {
        display: none;
    }
}