/*
Open Menu Burger Animation
*/

.close:nth-child(1):hover {
    opacity: 1!important;
    
}

.close:nth-child(2):hover {
    opacity: 1!important;
    
}

.close:nth-child(3):hover {
    opacity: 1!important;
    
}

.open:nth-child(1) {
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    animation: Rectangle_top_rotation_1 400ms 0ms ease-in-out forwards, Rectangle_top_y_1 400ms 0ms ease-in-out forwards;
}

@keyframes Rectangle_top_rotation_1 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(45deg);
    }
}
@keyframes Rectangle_top_y_1 {
    from {
        x: 0px;
        y: 0px;
    }

    to {
        x: 0px;
        y: 8px;
    }
}

.open:nth-child(2) {
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    transform-box: fill-box;
    animation: Rectangle_111__x__1 400ms 0ms ease-in-out forwards, Rectangle_111__rotation__1 400ms 0ms ease-in-out forwards;
}

@keyframes Rectangle_111__x__1 {
    from {
        x: 20px;
        y: 8px;
    }

    to {
        x: 10px;
        y: 8px;
    }
}
@keyframes Rectangle_111__rotation__1 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(45deg);
    }
}

.open:nth-child(3) {
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    animation: Rectangle_112__y__1 400ms 0ms ease-in-out forwards, Rectangle_112__rotation__1 400ms 0ms ease-in-out forwards;
}

@keyframes Rectangle_112__y__1 {
    from {
        x: 0px;
        y: 16px;
    }

    to {
        x: 0px;
        y: 8px;
    }
}
@keyframes Rectangle_112__rotation__1 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-45deg);
    }
}




/*
Close Menu Burger Animation
*/

.close:nth-child(1) {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    animation: Rectangle_top_rotation_2 400ms 0ms ease-in-out forwards, Rectangle_top_y_2 400ms 0ms ease-in-out forwards;
}

@keyframes Rectangle_top_rotation_2 {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(0deg);
    }
}
@keyframes Rectangle_top_y_2 {
    from {
        x: 0px;
        y: 8px;
    }

    to {
        x: 0px;
        y: 0px;
    }
}

.close:nth-child(2) {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    transform-box: fill-box;
    animation: Rectangle_111__x__2 400ms 0ms ease-in-out forwards, Rectangle_111__rotation__2 400ms 0ms ease-in-out forwards;
}

@keyframes Rectangle_111__x__2 {
    from {
        x: 10px;
        y: 8px;
    }

    to {
        x: 20px;
        y: 8px;
    }
}
@keyframes Rectangle_111__rotation__2 {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.close:nth-child(3) {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center;
    animation: Rectangle_112__y__2 400ms 0ms ease-in-out forwards, Rectangle_112__rotation__2 400ms 0ms ease-in-out forwards;
}

@keyframes Rectangle_112__y__2 {
    from {
        x: 0px;
        y: 8px;
    }

    to {
        x: 0px;
        y: 16px;
    }
}
@keyframes Rectangle_112__rotation__2 {
    from {
        transform: rotate(-45deg);
    }

    to {
        transform: rotate(0deg);
    }
}
