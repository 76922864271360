.comparison-content {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark-sea-green);
    color: var(--light);
    overflow: hidden!important;
    padding-top: calc(var(--extra-large-indent)*2)!important;
    padding-bottom: calc(var(--extra-large-indent)*2)!important;
}

.comparison-content-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.comparison-image {
    width: 80%;
}

.comparison-image-mobile {
    display: none;
}

@media only screen and (max-width: 768px) {

    .comparison-image {
        display: none;
    }

    .comparison-image-mobile {
        width: 100%;
        display: flex;
    }
}