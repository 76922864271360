.reasons-container {
    width: 100%;
    background-color: var(--dark);
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.reasons-main-text {
    margin-bottom: var(--super-extra-large-indent);
}

.reasons-main-header-container{
    overflow: hidden!important;
    margin-bottom: var(--large-indent)!important;
}

.reasons-main-text-description {
    margin-bottom: var(--large-indent)!important;
}

.reasons-container-inner {
    margin-top: var(--large-indent);
    margin-bottom: var(--large-indent);
}

.reasons-image {
    overflow: hidden!important;
    margin-bottom: var(--large-indent);
}

.reasons-header-container {
    overflow: hidden!important;
    margin-bottom: var(--large-indent)!important;
}