.contacts-content {
    display: flex;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark);
    color: var(--light);
    overflow: hidden!important;
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.contacts-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
    margin-bottom: var(--large-indent)!important;
}

.contacts-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--super-extra-large-indent);
}

.contacts-three-dots{
    margin-top: var(--regular-indent);
    margin-bottom: var(--regular-indent)
}

.contacts-text {
    padding-bottom: 14px;
}

.contacts-address {
    margin-top: var(--super-extra-small-indent)!important;
    margin-bottom: var(--super-extra-small-indent)!important;
}

.contacts-telephone {
    margin-top: 18px;
}

.contacts-three-dots {
    display: none;
}

@media only screen and (max-width: 768px) {
    .contacts-three-dots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
  }