.prepare-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark);
    color: var(--light);
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
    overflow: hidden!important;
}

.prepare-small-header {
    display: flex;
    flex-direction: row;
    vertical-align: top;
    align-items:flex-start;
    overflow: hidden!important;
    margin-bottom: var(--extra-small-indent)!important;
}


.prepare-header-text {
    overflow: hidden!important;
    margin-bottom: var(--large-indent);
}

.prepare-rectangle {
    margin-right: var(--extra-small-indent);
}

.prepare-container-layer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--dark-grey);
}

.prepare-container-layer-text {
    
    padding-left: var(--large-indent);
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.prepare-container-layer-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-right: 0!important;
    padding-left: 0!important;
}

.prepare-image {
    
    height: 700px;
    padding-right: 0!important;
}

.prepare-text-description {
    margin-bottom: var(--medium-indent)!important;
    
}


@media only screen and (max-width: 992px) {

    .prepare-image {
        width: 100%;
        height: auto;
        background-color: var(--dark-grey);
    }
    
    .prepare-header {
        display: flex;
        align-items: baseline;
        text-align: center!important;
    }

    .prepare-container-layer {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
    }

    .prepare-container-layer-text {
        background-color: transparent;
        padding-left: 15px!important;
        padding-right: 15px;
    }

    
    .prepare-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}