
.mission-content {
    display: flex;
    position: relative;
    z-index: 5!important;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    color: var(--light);
    background-color: var(--dark);  
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.mission-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
    margin-bottom: var(--large-indent);
}

.mission-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: var(--super-extra-large-indent);
}

.clients-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden!important;
    margin-top: var(--super-extra-large-indent);
    margin-bottom: var(--large-indent);
}

.client-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--super-extra-large-indent);
}

.software-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: hidden!important;
    margin-top: var(--super-extra-large-indent);
    margin-bottom: var(--large-indent);
}

.software-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: var(--small-indent);
    padding-right: var(--small-indent);
    padding-bottom: var(--large-indent);
}

.software-logo-group {
    align-items: center;
    padding-bottom: var(--large-indent);
}

.action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.action-container-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.action-text-container {
    overflow: hidden!important;
}

.action-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--super-extra-large-indent)!important;
    margin-bottom: var(--large-indent)!important;
}

.mission-tree,
.mission-cube {
    width: var(--large-indent);
    margin-bottom: var(--medium-indent);
}