.plan-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark);
    color: var(--light);
    margin-top: var(--super-extra-large-indent)!important;
    margin-bottom: var(--super-extra-large-indent)!important;
    overflow: hidden!important;
}

.plan-small-header {
    display: flex;
    flex-direction: row;
    vertical-align: top;
    align-items:flex-start;
    overflow: hidden!important;
    margin-bottom: var(--extra-small-indent)!important;
}

.plan-header-text {
    overflow: hidden!important;
    margin-bottom: var(--large-indent);
}

.plan-rectangle {
    margin-right: var(--extra-small-indent);
}

.plan-container-layer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.plan-container-layer-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plan-image {
    width: 100%;
}

.plan-image-background {
    position: absolute!important;
    background-color: var(--dark-grey);
    left: 0;
    width: 40%;
    height: 100%;
    z-index: -1!important;
}

.plan-text-description {
    margin-bottom: var(--large-indent)!important;  
}

@media only screen and (max-width: 992px) {
    
    .plan-container-layer-media {
        margin-bottom: var(--extra-large-indent);
    }

    .plan-image-background {
        height: 100%;
        width: 100%;
    }

}