.sell-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark);
    color: var(--light);
    overflow: hidden!important;
    padding-bottom: var(--super-extra-large-indent);
}

.sell-header {
    display: flex;
    flex-direction: row;
    vertical-align: top;
    align-items: baseline;
    margin-bottom: var(--large-indent);
}

.sell-small-header {
    display: flex;
    flex-direction: row;
    vertical-align: top;
    align-items:flex-start;
    overflow: hidden!important;
    margin-bottom: var(--extra-small-indent)!important;
}

.sell-header-text {
    overflow: hidden!important;
    margin-bottom: var(--large-indent);
}

.sell-rectangle {
    margin-right: var(--extra-small-indent);
}

.sell-container-layer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: var(--super-extra-large-indent);
    padding-bottom: calc(var(--super-extra-large-indent)*2);
}

.sell-container-layer-media {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden!important;
}

.sell-image {
    width: 100%;
}

.sell-image-background {
    position: absolute!important;
    background-color: var(--dark-grey);
    margin-top: var(--super-extra-large-indent)!important;
    right: 15px;
    width: 100%;
    height: 87%;
    z-index: -1!important;
}

.sell-text-description {
    margin-bottom: var(--medium-indent)!important;   
}

.action-text-container {
    overflow: hidden!important;
}

@media only screen and (max-width: 992px) {
    
    .sell-header {
        display: flex;
        align-items: baseline;
        text-align: center!important;
    }

    .sell-container-layer-media {
        padding-right: 0px!important;
        margin-bottom: var(--extra-large-indent);
    }

    .sell-image-background {
        right: 0;
        width: 100%;
    }

    .sell-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}