.architecture-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark);
    color: var(--light);
    overflow: hidden!important;
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.architecture-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
    margin-bottom: var(--large-indent)!important;
}

.architecture-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--super-extra-large-indent)!important;
}

.architecture-container-layer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.architecture-container-layer-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.architecture-image {
    width: 80%;
}

.cloud,
.platform-icon,
.architecture-cube {
    margin-right: var(--extra-small-indent);
    height: var(--regular-indent);
}

.architecture-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    overflow: hidden!important;
    margin-bottom: var(--large-indent)!important;
}

.architecture-text-description {
    overflow: hidden!important;
    margin-bottom: var(--extra-large-indent)!important;
}


@media only screen and (max-width: 992px) {
    
    .architecture-container-layer-media {
        margin-bottom: var(--extra-large-indent);
    }

    .architecture-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center!important;
    }

    .architecture-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .architecture-text-description,
    .architecture-text-description-last {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .cloud,
    .platform-icon,
    .architecture-cube {
        margin-right: 0px!important;
        width: var(--large-indent);
        margin-bottom: var(--regular-indent);
}
  }