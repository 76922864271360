.menu-container {   
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    color: var(--light);
}

.navbar-brand {
  padding-top: 0rem;
  padding-bottom: .314rem;
}

.navbar-toggler {
  padding: 0rem 0rem;
}

.menu-secondary-background {
    background-color: var(--yellow);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.menu-layer {
    position: relative;
    background: var(--dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden!important;
}

.pagenames {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 0px;
}

.pagenames a {
    text-decoration: none;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--light);
    transition: .4s ease-in-out;
}

.pagenames h1 {
  cursor: pointer!important;
  margin-top: calc(var(--regular-indent)/2)!important;
  margin-bottom: calc(var(--regular-indent)/2)!important;
}

.pagenames a:hover {
    text-decoration: none;
    color: var(--light);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--light);
    transition: .4s ease-in-out;
}

@media only screen and (max-width: 768px) {

  .pagenames a {
    -webkit-text-stroke-width: .5px;
  }
}