.navbar-custom  {
    position: fixed;
    z-index: 10!important;
    width: 100%;
    background-color: transparent;
    overflow: hidden;   
}

.navbar-toggler {
    border-width: 0px!important;
}