.sustainability-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2!important;
    background-color: var(--dark-sea-green);
    color: var(--light);
    overflow: hidden!important;
}

.sustainability-image {
    width: 100%;
}

.sustainability-content-text-container {
    padding-bottom: var(--extra-large-indent)!important;
}

.sustainability-container-layer {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: var(--extra-large-indent);
    padding-bottom: var(--extra-large-indent);
    min-height: 100vh;
}

.sustainability-header-container {
    overflow: hidden!important;
}

.sustainability-dots {
    display: none;
}

.sustainability-header {
    margin-bottom: var(--large-indent)!important;
}

@media only screen and (max-width: 992px) {
    
    .sustainability-container-layer {
        padding-top: var(--super-extra-large-indent);
        padding-bottom: var(--super-extra-large-indent);
    }

    .sustainability-image {
        padding-top: var(--extra-large-indent);
    }

    .sustainability-content-text {
        padding-top: var(--extra-large-indent)!important;
    }

    .sustainability-dots {
        display: flex;
    }
}