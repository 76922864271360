.workflow-content {
    display: flex;
    position: relative;
    z-index: 5!important;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    color: var(--light);
    background-color: var(--dark);  
    padding-top: var(--super-extra-large-indent);
    padding-bottom: var(--super-extra-large-indent);
}

.workflow-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden!important;
    margin-bottom: var(--large-indent)!important;
}

.workflow-text-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: var(--super-extra-large-indent);
}

.workflow-image-desktop {
    width: 80%;
}

.workflow-image-tablet {
    display: none;
}

@media only screen and (max-width: 1140px) {

    .workflow-image-desktop {
        display: none;
    }

    .workflow-image-tablet {
        display: flex;
        width: 40%;
    }
  }

@media only screen and (max-width: 576px) {

    .workflow-image-tablet {
        display: flex;
        width: 80%;
    }
}
